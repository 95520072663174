import '../assets/scss/styles.scss';

import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import qs from 'qs';
import withLocation from '../App/withLocation';
import Header from '../App/Header';
import superagent from 'superagent';
import { Col, Container, Row } from 'reactstrap';
import Helmet from 'react-helmet';
import { Img } from 'react-image';

const IndexPage = () => {
  const [isLoadingPhotoGroup, setIsLoadingPhotoGroup] = useState(true);
  const [photoGroup, setPhotoGroup] = useState();
  const location = useLocation();
  const { g: photoGroupIdentifier } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const template1Url = `https://scottrupprecht.azurewebsites.net/photobooth/groups/${photoGroupIdentifier}/template1`;
  // const template1Url = `https://localhost:44357/photobooth/groups/${photoGroupIdentifier}/template1`;

  useEffect(() => {
    loadPhotoGroup();
  }, []);

  useEffect(() => {
    (async () => {
      const hub = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Error)
        // .withUrl('https://localhost:44357/photoUploadHub')
        .withUrl('https://scottrupprecht.azurewebsites.net/photoUploadHub')
        .withAutomaticReconnect()
        .build();

      await hub.start();

      hub.on('photosUploaded', (uploadedPhotoGroupIdentifier) => {
        if (uploadedPhotoGroupIdentifier.toLowerCase() !== photoGroupIdentifier.toLowerCase()) {
          return;
        }
        loadPhotoGroup();
      });
    })();
  }, []);

  function loadPhotoGroup () {
    (async () => {
      try {
        setIsLoadingPhotoGroup(true);
        const { body } = await superagent.get(`https://scottrupprecht.azurewebsites.net/api/photobooth/groups/${photoGroupIdentifier}`);
        // body.photos = [];
        setPhotoGroup(body);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoadingPhotoGroup(false);
      }
    })();
  }

  return (
    <div className="photobooth">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Halant:wght@700&family=Sura:wght@700&display=swap"
          rel="stylesheet"
        />
        <title>Photobooth | Scott & Micaela</title>
      </Helmet>

      <Header />

      {isLoadingPhotoGroup && (
        <div className="text-center my-5">
          <h1><i className="mdi mdi-spin mdi-refresh" /> Loading Photos...</h1>
        </div>
      )}

      {!isLoadingPhotoGroup && (
        <>
          {(!photoGroup || !photoGroup.photos.length) && (
            <Container className="mt-5 text-primary text-center" style={{ minHeight: '100vh' }}>
              <Row>
                <Col md={{ size: 6, offset: 3 }} className="d-flex justify-content-center">
                  <img className="img-fluid" src="/img/not-loaded.jpg" alt="Not loaded" style={{ maxWidth: 600 }} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="d-flex flex-column justify-content-center">
                  <h2>Images Not Loaded...Yet...</h2>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <p>
                    The images that were taken are on there way here now, this may
                    take some time given the celebration is in the middle of nowhere
                    and WiFi may be hard to come by. Give it some time, this page will
                    automatically refresh when the images are ready...
                  </p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <i className="mdi mdi-spin mdi-36px mdi-refresh" />
                </Col>
              </Row>
            </Container>
          )}

          {!!photoGroup.photos.length && (
            <Container className="mt-4">
              <Row>
                {
                  _.map(photoGroup.photos, (photo) => (
                    <Col key={photo.photoId} className="mb-4" md={6}>
                      <a href={`https://scottandmicaela.blob.core.windows.net/photobooth/${photo.filename}`} target="_blank" rel="noreferrer noopener">
                        <Img
                          alt="Image from the Photobooth at Scott and Micaela's Wedding Celebration"
                          className="img-fluid"
                          src={`https://scottandmicaela.blob.core.windows.net/photobooth/${photo.filename}`}
                          loader={(
                            <div className="image-placeholder" style={{ height: 270 }}>
                              <i className="mdi mdi-36px mdi-spin mdi-refresh" />
                            </div>
                          )}
                        />
                      </a>
                    </Col>
                  ))
                }
              </Row>

              {photoGroup.photos.length === 4 && (
                <Row>
                  <Col>
                    <a href={template1Url} target="_blank" rel="noreferrer noopener">
                      <Img
                        alt="Image from the Photobooth at Scott and Micaela's Wedding Celebration"
                        className="img-fluid"
                        src={template1Url}
                        loader={(
                          <div className="image-placeholder" style={{ height: 270 }}>
                            <div>
                              <i className="mdi mdi-36px mdi-spin mdi-refresh" />
                            </div>
                            <div>
                              Creating Postcard Image...
                            </div>
                          </div>
                        )}
                      />
                    </a>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </>
      )}
    </div>
  );
};

export default withLocation(IndexPage);
